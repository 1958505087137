@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #11001c;
  color: #f1d8ff;
  background: radial-gradient(circle at 50% 50%, #2c3e50, #11001c);
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--primary);
}

footer a {
  color: inherit;
  text-decoration: none;
}

footer a:hover {
  color: var(--primary);
}

footer {
  position: relative;
  z-index: 10;
}

footer .footer-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

footer .footer-links a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer .footer-links a:hover {
  color: var(--primary);
}

input, textarea {
  color: inherit;
  background-color: inherit;
  border: 1px solid var(--primary);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

input:focus, textarea:focus {
  border-color: var(--secondary);
  outline: none;
}

.container {
  position: relative;
  z-index: 1;
}

form {
  position: relative;
  z-index: 2;
}

.modal-content {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1a202c;
  color: #f1d8ff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1000;
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.modal-content button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  background-color: #74f7d5;
  color: #1a202c;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-content button:hover {
  background-color: #2c84f3;
  transform: scale(1.05);
}

.modal-content input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 6px;
  border: 1px solid #74f7d5;
  background-color: #2d3748;
  color: #f1d8ff;
  font-size: 16px;
}

.modal-content input:focus {
  outline: none;
  border-color: #2c84f3;
  box-shadow: 0 0 5px rgba(44, 132, 243, 0.5);
}

.modal-content ul {
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: disc;
}

.modal-content li {
  margin: 8px 0;
  font-size: 16px;
  color: #f1d8ff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #cd76ff #11001c;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #11001c;
}

*::-webkit-scrollbar-thumb {
  background-color: #cd76ff;
  border-radius: 10px;
  border: 2px solid #11001c;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #00bcd4;
}

button {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.to-purple-900 {
  --tw-gradient-to: #4c1d95;
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.to-teal-500 {
  --tw-gradient-to: #00bcd4;
}

.from-teal-500 {
  --tw-gradient-from: #00bcd4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0));
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6;
}

.text-primary {
  color: #cd76ff;
}

.text-secondary {
  color: #00bcd4;
}

.bg-primary {
  background-color: #cd76ff;
}

.bg-secondary {
  background-color: #00bcd4;
}
